html { font-size: 62.5%; }

$font-base:  'Open Sans', sans-serif;
$font-bold:  'opensansbold', sans-serif;
$font-title: 'Open Sans', sans-serif;
$font-basline : 'NeouThin';
$font-icon:  'boullu';
$image-path: '/images/';

$white: #fff;
$black: #000;

$color-1:  #e96c4c; //orange
$color-2:  #475a77; //bleu
$color-3:  #e6e6e6; //gris

$color-bg: $white;
$color-text: $black;
$color-negatif: $white;

$color-link: $color-1;
$color-link-hover: lighten($color-1, 8%);

$color-error: #FF4E00;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;
$color-youtube:     #EA1800;

$grid-gutter-base:    20px / 2;
$grid-gutter-tablet:  40px / 2;
$grid-gutter-desktop: 60px / 2;
$grid-gutter-wide:    80px / 2;

$grid-gutter-tablet--alt:  8px / 2;
$grid-gutter-desktop--alt: 8px / 2;
$grid-gutter-wide--alt:    8px / 2;