/***** Open sans regular *****/
@font-face {
    font-family: 'opensansregular';
    src: url('../fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

/***** Open sans bold *****/
@font-face {
    font-family: 'opensansbold';
    src: url('../fonts/OpenSans-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


/***** Neou *****/
@font-face {
    font-family: 'NeouThin';
    src: url('../fonts/NeouThin.eot');
    src: url('../fonts/NeouThin.eot') format('embedded-opentype'),
         url('../fonts/NeouThin.woff2') format('woff2'),
         url('../fonts/NeouThin.woff') format('woff'),
         url('../fonts/NeouThin.ttf') format('truetype'),
         url('../fonts/NeouThin.svg#NeouThin') format('svg');
}



@font-face {
    font-family: 'boullu';
    src: url('../fonts/boullu.eot?hnuihv');
    src: url('../fonts/boullu.eot?hnuihv#iefix') format('embedded-opentype'),
         url('../fonts/boullu.ttf?hnuihv') format('truetype'),
         url('../fonts/boullu.woff?hnuihv') format('woff'),
         url('../fonts/boullu.svg?hnuihv#boullu') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'boullu' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-digital:before {
  content: "\e900";
}
.icon-count:before {
  content: "\e903";
}
.icon-law:before {
  content: "\e907";
}
.icon-social:before {
  content: "\e90a";
}
.icon-teach:before {
  content: "\e90b";
}
.icon-google-plus:before {
  content: "\e901";
}
.icon-youtube:before {
  content: "\e902";
}
.icon-logo-linkedin:before {
  content: "\e90e";
}
.icon-viadeo-logo:before {
  content: "\e90f";
}
.icon-social-twitter:before {
  content: "\e90d";
}
.icon-social-facebook:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-lock:before {
  content: "\e906";
}
.icon-chevron-small-right:before {
  content: "\e90c";
}
.icon-phone:before {
  content: "\e908";
}
.icon-mail:before {
  content: "\e909";
}

