html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html {
	text-size-adjust: 100%;
}


body {
	background-color: $color-bg;
	
	@include font-size(14);
	font-family: $font-base;
	color: $color-text;
	
	&.noscroll {
		overflow: hidden;
	}
}

.container {
	overflow: hidden;
}

.grecaptcha-badge {
	display: none;
}


/* 
 ######     #######   ##     ##  ##     ##  ##     ##  ##    ##   
##    ##   ##     ##  ###   ###  ###   ###  ##     ##  ###   ##   
##         ##     ##  #### ####  #### ####  ##     ##  ####  ##   
##         ##     ##  ## ### ##  ## ### ##  ##     ##  ## ## ##   
##         ##     ##  ##     ##  ##     ##  ##     ##  ##  ####   
##    ##   ##     ##  ##     ##  ##     ##  ##     ##  ##   ###   
 ######     #######   ##     ##  ##     ##   #######   ##    ##   
*/
.wrapper {
	padding: 20px;
	margin: 0 auto;

	@include mq($from: tablet) {
		padding: 0;
		width: 700px;
	}

	@include mq($from: desktop) {
		width: 960px;
	}

	@include mq($from: wide) {
		width: 1100px;
	}
}

.wrapper--header {
	position: fixed;
	z-index: 130;
	background-color: $white;
	width: 100%;
	height: auto;
	padding: 20px;

	@include mq($from: tablet) {
		margin: 0 auto;
		padding: 20px;


		transition: padding 0.2s ease-in-out;
	}

	@include mq($from: desktop) {
		position: relative;
		z-index: 1;
		margin: 0 auto;

		transition: padding 0.2s ease-in-out;

		.ontop & {
			padding: 30px 0;
		}
	}
}

.wrapper--footer {

	@include mq($from: tablet) {
		padding: 20px 0;
	}
}

.wrapper--section {
	padding: 60px 20px;

	@include mq($from: tablet) {
		padding: 50px 0;

		&.wrapper--section--pourquoi {
			margin-bottom: 80px;
		}
	}

	@include mq($from: desktop) {
		padding: 60px 0;
	}

	@include mq($from: wide) {
		padding: 80px 0;
	}
}

.wrapper--section--title {
	padding: 40px 20px 20px 20px;

	@include mq($from: tablet) {
		padding: 60px 0 20px 0;
	}

	@include mq($from: desktop) {
		padding: 100px 0 20px 0;
	}

	@include mq($from: wide) {
		padding: 120px 0  20px 0;
	}
}


.section {
	position: relative;
	z-index: 0;
	background-color: $white;

	&.section--color {
		background-color: $color-1;
		color: $white;
	}

	&.section--color2 {
		background-color: $color-3;
	}

	&.section--color--pattern {
	 	background: url('#{$image-path}image-back-top.jpg');
	 	color: $white;
	}

}



.col-smaller--left {

	@include mq($from: desktop) {
		padding-right: 80px;
	}

	@include mq($from: wide) {
		padding-right: 120px;
	}
}

.col-smaller--right {

	@include mq($from: desktop) {
		padding-left: 80px;
	}

	@include mq($from: wide) {
		padding-left: 120px;
	}
}

.intro {
	margin: 10px auto 30px auto;
	padding: 15px;
	background-color: $color-3;

	@include font-size(15);
	font-style: italic;
	line-height: 1.4em;

	@include mq($from: desktop) {
		margin: 12px auto 46px auto;
		text-align: justify;
	}
}


/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */

.title-lev1 {
	display: inline-block;
	width: 100%;
	position: relative;
	z-index: -1;
	margin: 0 0 50px 0;
	font-weight: 300;
	text-transform: uppercase;
	@include font-size(24);

	@include mq($from: tablet) {
		@include font-size(30);
	}

	&.title-lev1--separator {
		margin: 0 0 40px 0;

		&:after {
		content: '';
		position: absolute;
		bottom: -20px;
		left: 0;
		border-bottom: 3px solid $color-1;
		height: 1px;
		width: 35px;
		}

	}

	&.title-lev1--capital {
		@include font-size(25);
	}

	&.title-lev1--width {
		@include mq($from: desktop) {
			width: 60%;
		}	
	}
}

.title-lev2 {
	display: inline-block;
	width: auto;
	position: relative;
	margin: 20px 0 1.8em 0;

	font-family: $font-bold;
	@include font-size(16);

	@include mq($from: desktop) {
		margin: 0 0 20px 0;
		@include font-size(18);
	}

	&.title-lev2--separator {
		margin: 0 0 40px 0;

		&:after {
		content: '';
		position: absolute;
		bottom: -20px;
		left: 0;
		border-bottom: 3px solid $color-1;
		height: 1px;
		width: 35px;
		}

	}

	&.title-lev2--separator2 {
		margin: 0 0 40px 0;

		&:after {
		content: '';
		position: absolute;
		bottom: -20px;
		left: 0;
		border-bottom: 3px solid #ce5637;
		height: 1px;
		width: 35px;
		}

	}

	&.title-lev2--icon {

		&:before {
			content: '';
			margin: 0 0 10px 0; 
		    display: block;
		    height: 48px;
		    width: 45px;
		    top: 0;
		    left: 0;
		    right: 0;
		    bottom: 0;
		    background: url(/images/sprite.png) no-repeat;
		    background-position: 1px -60px;
		}
	}

	&.title-lev2--icon2 {
		&:before {
			width: 40px;
		    background-position: -44px -60px;
		}
	}

	&.title-lev2--icon3 {
		&:before {
			width: 50px;
		    background-position: -84px -60px;
		}
	}

	&.title-lev2--margin {
		margin-left: 10px;

		@include mq($from: tablet) {
			margin-left: 20px;
		}

		@include mq($from: desktop) {
			margin-left: 30px;
		}

		@include mq($from: wide) {
			margin-left: 40px;
		}
	}

	&.title-lev2--2 {
		font-family: $font-bold;
		@include font-size(14);
	}
}

.main-link {
	display: inline-block;
	margin: 2em 0 0 0;
	padding: 12px 14px;
	border: 1px solid $black;
	border-color: rgba($black, 0.2);
	width: 100%;

	font-family: $font-bold;
	text-transform: uppercase;
	text-align: center;
	@include font-size(15);

	transition: all 0.2s ease-in-out;

	@include mq($from: tablet) {
		width: auto;
		text-align: left;
		margin: 1em 0 0 0;
	}

	@include mq($from: desktop) {
		padding: 14px 16px;
	}

	&:hover {
		background-color: $color-1;
		color: $white;
		border-color: $color-1;
	}

	&:after {
		font-family: $font-icon;

		/* Better Font Rendering =========== */
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;

	    content: "\e905";
	    @include font-size(11);
	    margin-left: 12px;

	    transition: margin-left 0.2s ease-in-out, color 0.2s ease-in-out;

	    @include mq($from: desktop) {
			margin-left: 14px;
		}

		@include mq($from: wide) {
			margin-left: 16px;
		}
	}

	.no-touch & {
		&:hover {
			color: $white;
			border-color: rgba($color-1, 0.3);

			&:after {
				margin-left: 24px;
			}
		}
	}
}

.alt-link {
	display: inline-block;

	font-family: $font-bold;
	text-transform: uppercase;
	color: $black;
	@include font-size(13);

	transition: color 0.2s ease-in-out;

	@include mq($from: tablet) {
		@include font-size(12);
	}

	&:after {
		font-family: $font-icon;

		/* Better Font Rendering =========== */
	    -webkit-font-smoothing: antialiased;
	    -moz-osx-font-smoothing: grayscale;

	    content: "\e905";
	    @include font-size(10);
	    margin-left: 10px;

	    transition: margin-left 0.2s ease-in-out, color 0.2s ease-in-out;
	}

	.no-touch & {
		&:hover, &:focus {
			color: $color-2;

			&:after {
				margin-left: 18px;
				color: $color-2;
			}
		}
	}
}

p {
	line-height: 1.4;
	margin: 0 0 1.2em 0;

	@include mq($from: desktop) {
		margin: 0 0 1.4em 0;
	}

	strong {
		font-family: $font-bold;
	}
}

a {
	color: $color-link;

	transition: color 0.2s ease-in-out;

	.no-touch & {
		&:hover, &:focus {
			color: $color-link-hover;
		}
	}
}

.editor-text {
	color: $color-text;

	p {
		line-height: 1.5;
		margin-bottom: 1.5em;
	}

	a {
		color: $color-link;
		display: inline-block;
		// border-bottom: 1px solid $color-link;

		transition:
			color 0.2s ease-in-out;
			// border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				color: $color-link-hover;
				// border-color: lighten($color-link, 10%);
			}
		}
	}

	h2 {
		position: relative;
		display: inline-block;
		margin: 0.5em 0 1.5em 0;

		font-family: $font-title;
		@include font-size(16);

		@include mq($from: desktop) {
			@include font-size(17);
		}

		@include mq($from: wide) {
			@include font-size(18);
		}

		&:after {
			display: block;
			content: "";
			width: 100%;
			height: 2px;
			background-color: $black;
			position: absolute;
			top: 110%;

			@include mq($from: desktop) {
				display: none;
			}
		}
	}
}


/* 
##     ##  ########      ###     ########   ########   ########   
##     ##  ##           ## ##    ##     ##  ##         ##     ##  
##     ##  ##          ##   ##   ##     ##  ##         ##     ##  
#########  ######     ##     ##  ##     ##  ######     ########   
##     ##  ##         #########  ##     ##  ##         ##   ##    
##     ##  ##         ##     ##  ##     ##  ##         ##    ##   
##     ##  ########   ##     ##  ########   ########   ##     ## 
*/
.header-social {
	@include mq($until: desktop) {
		display: none;
	}
	position: fixed;
	background-color: $color-1;
	width: 100%;
	height: 38px;
	z-index: 120;

	.wrapper {
		margin-top: 0;
		margin-bottom: 0;
	}

	.social-header--content {
		width: 100%;
	}

	.baseline {
		padding: 9px 0;
		float: left;
		
		@include font-size(14);
		color: $white;
	}

	.conteneur--social {
		float: right;
	}

	.social--label {
		position: relative;
		top: -15px;
		display: inline-block;
		width: auto;
		padding: 0 10px;

		@include font-size(12);
		color: $white;
	}
}

.social--list {
	display: inline-block;
	width: auto;
	margin: 0;
	padding: 0;
	max-height: 38px;
	padding: 0 10px 0 4px;

	li {
		display: inline-block;
		float: left;
		padding: 8px 2px;
	}

	a {
		display: inline-block;
		float: left;
		background-color: $color-bg;
		color: $color-1;
		margin: 0;
		padding: 0;
		height: auto;
		width: auto;
		padding: 3px;
		transform: scale(1);

		text-align: center;
		@include font-size(16);

		transition: all 0.2s ease-in-out;

		&.social--bouton {
			color: #3e3e3e;
			width: 120px;
			height: 22px;
			@include font-size(12);
		}

		.no-touch & {
			&:hover, &:focus {
				background-color: $color-3;
			}
		}
	}
}

.header {
	position: fixed;
	z-index: 115;
	top: 0;

	@include mq($from: desktop) {
		top: 0;
		width: 100%;
		background-color: $white;

		.ontop & {
			position: relative;
			background-color: transparent;
		}
	}
}

/***** LOGO *****/
.logo {
	display: block;
	float: left;
	text-indent: -9999px;
	z-index: 999;
	position: relative;
	
	width: 60px;
	height: 60px;
	background: url('#{$image-path}logo.png') 0 0 no-repeat;
	background-size: 100%;

	@include retina {
		background-image: url('#{$image-path}logo@2x.png');
	}

	@include mq($from: tablet) {
		width: 80px;
		height: 80px;
	}

	@include mq($from: desktop) {
		width: 80px;
		height: 80px;
		transition: all 0.2s ease-in-out;

		@include retina {
			background-image: url('#{$image-path}logo@2x.png');
		}

		.ontop & {
			width: 120px;
			height: 120px;
			transition: all 0.2s ease-in-out;
		}
	}

	@include mq($from: wide) {

		@include retina {
			background-image: url('#{$image-path}logo@2x.png');
		}

		.ontop & {
			width: 120px;
			height: 120px;
		}
	}
}


.conteneur-logo {
	display: none;
	& a:after {
		background-color: $color-1 !important;
	}

	@include mq($from: desktop) {
		display: block;
		position: relative;
		top: 36px;		

		.ontop & {
			top: 51px;
		}
	}
}


/* 
##    ##      ###     ##     ##  
###   ##     ## ##    ##     ##  
####  ##    ##   ##   ##     ##  
## ## ##   ##     ##  ##     ##  
##  ####   #########   ##   ##   
##   ###   ##     ##    ## ##    
##    ##   ##     ##     ###     
*/
.navigation {
	z-index: 115;

	@include mq($until: desktop) {

		position: fixed;
		right: -100%;
		width: 100%;
		height: 100%;
		background-color: rgba($white, 0.9);

		transition: top 0.35s ease-in-out;

		&.open {
			right: 0;
		}
	}

	@include mq($from: desktop) {
		position: fixed;
		top:38px;
		width: 100%;
		height: 80px;
		background-color: $white;

		transition: all 0.2s ease-in-out;

		.ontop & {
			/* position: relative; */
		    top: 0;
		    height: 158px;
		}
	}
}

.menu {
	clear: both;
	z-index: 115;
	@include clearfix;

	@include mq($until: desktop) {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		text-align: center;

		transform: translateX(-50%) translateY(-50%);
	}

	@include mq($from: desktop) {
		position: relative;
	    display: inline-block;
	    top: -36px;
	    width: auto;
	    left: 50%;

	    transform: translateX(-50%);

		.ontop & {
			top: -13px;
		}
	}

	li {
		@include mq($until: desktop) {
			height: 14vh;
			line-height: 20vh;
		}

		@include mq($from: desktop) {
			display: inline-block;
			margin: 0 22px;
		}

		&:first-of-type {
			margin-left: 0;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	a {
		display: inline-block;
		position: relative;
		padding: 0 20px;
		margin: 4px auto;

		text-align: center;
		transition: color 0.3s;

		font-family: $font-base;
		@include font-size(20);
		line-height: 1.6em;
		text-transform: uppercase;
		color: $color-1;
		transition: all 0.2s ease-in-out;


		@include mq($from: desktop) {
			padding: 0;
			margin: 0;

			font-family: $font-base;
			@include font-size(13);
			color: $color-1;

			.ontop & {
				@include font-size(16);
			}
		}

		@include mq($from: wide) {
			@include font-size(13);


			.ontop & {
				@include font-size(16);
			}
		}

		&:after {
			display: block;
			content: "";
			height: 2px;
			width: 0;
			background-color: $color-1;

			transition: width 0.2s ease-in-out;

			@include mq($from: desktop) {
				background-color: $color-2;
			}
		}

		.no-touch & {
			&:hover, &:focus, &.current {
				color: $color-2;

				&:after {
					width: 100%;
				}
			}
		}

		.touch & {
			&.current {

				&:after {
					width: 100%;
				}
			}
		}
	}
}


/* 
########    #######    ######     ######    ##         ########   
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##         ##         ##         ##         
   ##      ##     ##  ##   ####  ##   ####  ##         ######     
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##      ##     ##  ##    ##   ##    ##   ##         ##         
   ##       #######    ######     ######    ########   ########   
*/
.nav-toggle {
	width: 60px;
	height: 60px;
	top: 5px;
	right: 0;
	z-index: 132;

	// background-color: yellow;

	@include mq($from: tablet) {
		width: 70px;
		height: 70px;
		top: 12px;
		right: 12px;
	}

	@include mq($from: desktop) {
		display: none;
	}
}

@mixin line {
	display: inline-block;
	width: 18px;
	height: 3px;
	background: $color-1;
	transition: 0.2s ease-in-out;

	@include mq($from: tablet) {
		width: 20px;
	}
}

.lines-button {
	position: relative;
	float: right;
	height: auto;
	width: auto;
	top: 15px;
	z-index: 101;
	display: inline-block;
	transition: .3s;
	cursor: pointer;
	user-select: none;
	padding: 0;

	@include mq($from: tablet) {
		top: 20px;
	}

	.no-touch .nav-toggle:hover &:not(.close) .lines, 
	.no-touch .nav-toggle:hover & .lines:before, 
	.no-touch .nav-toggle:hover & .lines:after {
		background: rgba($color-1, 0.8);
	}

	@include mq($from: desktop) {
		display: none;
	}
}

.lines {
	@include line;
	position: relative;

	&:before, &:after {
		@include line;
		position: absolute;
		left: 0;
		content: '';
		transform-origin: center center;
	}

	&:before { top: 6px; }
	&:after { top: -6px; }
}

.lines-button.close .lines {
	background: transparent;

	&:before, &:after {
		transform-origin: 50% 50%;
		top: 0;
		width: 20px;
	}
	&:before {
		transform: rotate3d(0,0,1, 45deg); 
	}
	&:after {
		transform: rotate3d(0,0,1, -45deg); 
	}
}


/* 
##         ####  ########   ##    ##    ######    
##          ##   ##         ###   ##   ##    ##   
##          ##   ##         ####  ##   ##         
##          ##   ######     ## ## ##    ######    
##          ##   ##         ##  ####         ##   
##          ##   ##         ##   ###   ##    ##   
########   ####  ########   ##    ##    ######    
*/
.link-list {
	@include clearfix();
	z-index: 15;

	width: 100%;
	height: auto;

	@include mq($until: tablet) {
		padding: 20px;
		border-bottom: 1px solid $white;
	}

	@include mq($from: tablet) {
		width: auto;
		position: fixed;
		right: 0;
		top: 240px;
	}

	li.link {
		position: relative;
		line-height: 30px;

		@include mq($until: tablet) {
			border-bottom: 1px solid rgba($color-1, 0.2);
		}

		@include mq($from: tablet) {
			display: block;
			width: 40px;
			height: 40px;
			line-height: 40px;
			margin: 0 0 1px 0;
		}

		@include mq($from: desktop) {
			width: 50px;
			height: 50px;
			line-height: 50px;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;

			font-family: "boullu";
			color: $color-1;
			text-align: center;

			transition: color 0.2s ease-in-out;

			@include mq($from: tablet) {
				display: block;
				top: 0;
				left: 0;
				background-color: $color-bg;
				width: 40px;
				height: 40px;
				line-height: 40px;

				@include font-size(20);
			}

			@include mq($from: desktop) {
				width: 50px;
				height: 50px;
				line-height: 50px;

				@include font-size(22);
			}
		}

		&.email, &.phone {

			@include mq($from: tablet) {
				margin: 0 0 1px 90px;
			}

			@include mq($from: desktop) {
				margin: 0 0 1px 80px;
			}
		}

		&.email {
			
			&:after {
				content: "\e909";
			}
		}

		&.phone {
			
			&:after {
				content: "\e908";
			}
		}

		&.service {
			
			&:after {
				content: "\e906";
			}
		}

		&.phone,
		&.service {
			width: 100%;

			input {
				display: none;
			}

			@include mq($until:tablet) {

				input:checked ~ form,
				input:checked ~ ul {
					transition: height 0.2s ease-in-out;
					height: 320px;
				}

				input:checked ~ ul {
					height: 115px;
				}
			}

			@include mq($from: tablet) {
				width: 40px;
				margin: 0 0 1px 90px;
			}

			@include mq($from: desktop) {
				width: 50px;
				margin: 0 0 1px 80px;
			}
		}

		&.service {
			border-bottom: none;
		}

		.go-to-link {	
			@include mq($until: tablet) {
				display: none;
			}

			@include mq($from: tablet) {
				display: block;
				width: 40px;
				height: 40px;
				opacity: 0;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 20;
			}

			@include mq($from: desktop) {
				width: 50px;
				height: 50px;
			}
		}

		.link-hover {
			@include clearfix;
			height: auto;

			@include mq($from: tablet) {
				display: block;
				background-color: $white;
				width: 140px;
				height: 40px;
				line-height: 40px;
				padding: 0 0 0 15px;

				@include font-size(13);

				transition: opacity 0.35s, transform 0.35s;
				transition-delay: 0.15s;
				transform: translate3d(40px,0,0);
			}

			@include mq($from: desktop) {
				height: 50px;
				line-height: 50px;
				transform: translate3d(50px,0,0);
			}

			&.link-hover--form {

				@include mq($from: tablet) {
					width: 300px;
					height: auto;
					padding: 0 0 20px 20px;
				}

				// @include mq($from: desktop) {
				// 	width: 260px;
				// }
			}
		}

		&:hover {

			&:after {
				color: darken($color-1, 10%);
			}

			.link-hover {

				@include mq($from: tablet) {
					opacity: 1;
					transition-delay: 0s;
					transform: translate3d(-140px,0,0);
				}
			}

			.link-hover--form {

				@include mq($from: tablet) {
					transform: translate3d(-240px,0,0);
				}
			}
		}

		.text-link {
			color: $color-1;
			font-family: $font-base;
			@include font-size(13);

			@include mq($until: tablet) {
				padding: 0 0 0 24px;
			}
		}

		a.text-link {
			transition: color 0.2s ease-in-out;

			&:hover {
				color: $color-link-hover;
			}
		}

		form,
		ul.acces-link-list {
			/// Accordion mobile ///
			@include mq($until: tablet) {
				padding: 0 0 0 24px;

				margin-top: -1px;
				overflow: hidden;
				height: 0px;
				position: relative;
				z-index: 10;

				transition: height 0.2s ease-in-out;
			}
			///
		}

		.form-layout {


			li {
				display: block;
				width: 100%;
				margin: 0 0 10px 0;

				@include mq($from: mobileLandscape) {
					width: 300px;
				}

				@include mq($from: tablet) {
					width: 200px;
				}

				input[type="text"],
				input[type="email"] {
					@include input_mobile();
					display: block;
					width: 100%;
					padding: 0 10px;
					height: 28px;
					line-height: 28px;
					border: 1px solid $black;
					border-color: rgba($black, 0.2);
					color: $black;
					@include font-size(12);
					font-family: $font-base;
				}

				textarea {
					@include input_mobile();
					display: block;
					width: 100%;
					padding: 10px;
					border: 1px solid $black;
					border-color: rgba($black, 0.2);
					color: $black;
					@include font-size(12);
					font-family: $font-base;
				}

				p.mention {
					@include font-size(11);
					font-style: italic;
					color: lighten($black, 60%);
				}

				&.submit {
					width: 120px;

					@include mq($from: tablet) {
						width: 90px;
						margin: 0;
					}

					.btn-submit {
						position: relative;
						display: block;
						width: 100%;
						
						height: 28px;
						line-height: 28px;
						background-color: $color-1;
						border: none;

						font-family: $font-base;
						@include font-size(12);
						text-align: center;
						color: $white;
						text-transform: uppercase;

						@include mq($from: tablet) {
							padding: 0 12px;
						}

						.no-touch & {
							transition: background-color 0.2s ease-in-out;

							&:hover, &:focus {
								background-color: darken($color-1, 10%);
							}
						}
					}
				}

				}
			}
		}

		.acces-link-list {

			li {
				height: 28px;
				line-height: 28px;

				position: relative;

				.alt-link {
					@include font-size(13);
					text-transform: initial;
				}

				span.info {
					position: absolute;
					display: inline-block;
					left: 60px;
					top: 4px;

					background-color: $color-1;
					padding: 0 10px;

					@include font-size(12);
					line-height: 1.8em;
					color: $white;

					opacity: 0;

					transition: opacity 0.3s ease-in-out, left 0.3s ease-in-out;

					@include mq($from: tablet) {
						left: -90%;
					}
				}

				.no-touch &:hover,
				.no-touchevents &:hover,
				.no-touch &:focus,
				.no-touchevents &:focus,
				.touch &:active,
				.touchevents &:active {
					
					span.info {
						left: 85px;
						opacity: 1;

						@include mq($from: tablet) {
							left: -80%;
						}
					}
				}		
			}
		}
	}

/* 
   ###     ########   ####     ###     ##    ##   ########   
  ## ##    ##     ##   ##     ## ##    ###   ##   ##         
 ##   ##   ##     ##   ##    ##   ##   ####  ##   ##         
##     ##  ########    ##   ##     ##  ## ## ##   ######     
#########  ##   ##     ##   #########  ##  ####   ##         
##     ##  ##    ##    ##   ##     ##  ##   ###   ##         
##     ##  ##     ##  ####  ##     ##  ##    ##   ########   
*/
.breadcrumb {
	@include mq($from: tablet) {
		
	}

	.wrapper {
		padding: 10px 20px;

		@include mq($from: tablet) {
			border-top: 1px solid rgba(167, 167, 167, 0.5);
			padding: 15px 0;
		}
	}

	ul li {
		position: relative;
		display: inline-block;
		width: auto;
		line-height: 20px;
		padding-right: 15px;
		margin: 0;

		&:before {
			display: inline-block;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;

			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;

			content: "\e90c";
			margin: 0 0 2px 0;
			color: $black;
			@include font-size(14);
			width: 8px;

			position: absolute;
			left: -15px;
			bottom: 0;
		}

		&:first-of-type:before {
			content: "";
		}

		&:last-of-type:before {
			color: $color-2;
		}

		a {
			font-family: $font-base;
			font-style: italic;
			-webkit-font-smoothing: antialiased;
			color: $black;
			@include font-size(13);

			border: none;

			.no-touch & {
				&:hover {
					color: $color-1;
				}
			}
		}

		&:last-of-type a {
			color: $color-2;
		}
	}
}


/* 
##     ##   #######   ##     ##  ########   
##     ##  ##     ##  ###   ###  ##         
##     ##  ##     ##  #### ####  ##         
#########  ##     ##  ## ### ##  ######     
##     ##  ##     ##  ##     ##  ##         
##     ##  ##     ##  ##     ##  ##         
##     ##   #######   ##     ##  ########   
*/

.slider {
	position: relative;
	width: 100%;
	height: 379px;
	margin: auto;
	background: url('#{$image-path}image-back-top.jpg') repeat;
	padding-top:100px;
	
	overflow: hidden;
	
	@include mq($from: tablet) {
    	height: 600px;
    }

    @include mq($from: wide) {
    	height: 800px;
    }
}

.slider-content {
	position: relative;
	top: 50%;
	text-align: center;
	overflow: hidden;

	transform: translateY(-50%);
}

.slider-img {
	position: relative;
	display: none;

	@include mq($from: tablet) {
    	display: inline-block;
    	img {
    		height: 500px;
    	}
    }
    @include mq($from: desktop) {
    	img {
    		height: 750px;
    	}
    }

     @include mq($from: wide) {
    	top:-50px;
    }
}



.slider-baseline {
	position: relative;
	display: inline-block;


		text-align: center;
		font-family: $font-basline;
		@include font-size(40);
		color: $white;
		text-transform: uppercase;


		@include mq($from: tablet) {
			left: 30px;
			top: 35px;		
			text-align: left;

	    }

	    @include mq($from: desktop) {
			left: 30px;
			top: 30px;
			
			text-align: left;
			@include font-size(45);

	    }
		
		@include mq($from: wide) {
			left: 30px;
			top: 60px;

			text-align: left;
			@include font-size(60);
	    }
	
}

.arrow-nav {
	display: none;
	position: absolute;
	left: 50%;
	width: 60px;
	height: 60px;
	bottom: 65px; 

	transform: translateX(-50%);

	background: url('#{$image-path}sprite.png') no-repeat;
	background-position: -61px top;

	@include mq($from: tablet) {
		display: inline-block;
    }


    &.arrow-nav--black {
    	position: relative;
		background: url('#{$image-path}sprite.png') no-repeat;
		bottom: 10px;
	}
}
 
.img-homme2 {
	display: none;	

	@include mq($from: tablet) {
		display: block;
			position: relative;
			margin-top: -300px;
	}
}

/***** POURQUOI AIR H *****/

.frame-text {

	&.bottom {
		margin: 20px 0 0 0;

	    @include mq($from: tablet) {
			position: relative;
			margin: 50px 0 0 0;
	    	bottom: 0;
	    }
	}

	.citation {
		font-style: italic;
		color: $color-2;
	}


	&.frame-text--white {
		position: relative;
		top: 60px;
		z-index: 1;
		background-color: $white;
  		padding: 40px;
  		margin: 0 0 50px 0;
  		border-bottom: 5px solid #d7d9e0;

  		
  		& .title-lev2 {
  			color: $color-2;
  		}

  		@include mq($from: desktop) {
  			width: auto;
			@include font-size(14);
		}
  		

  		.frame-text--listing {
  			padding: 20px 0 0 20px;
  			position: relative;
  			text-transform: uppercase;

  			&:before {
  				content: "";
  				position: absolute;
  				top: 27px;
  				left: 0px;
  				width: 5px;
  				height: 5px;
  				margin-right: 10px;

  				background-color: $color-2;
  			}
  		}
	}

	&.frame-text--white2 {
		display: none;

		.frame-text--text{
  			width: 130px;
  		}

  		img {
			position: relative;
			width: 250px;
			top: -199px;
    		right: -20px;

    		@include mq($from: desktop) {
				top: -200px;
    			right: -50px;
				}

			}

  		@include mq($from: tablet) {
  			display: block;
  			position: absolute;
  			background-color: $color-2;
  			bottom: 0;
  			width: 225px;
  			height: 210px;
		}
		
  		@include mq($from: desktop) {
  			height: 185px;
  			width: 300px;
		}

		@include mq($from: wide) {
  			height: 195px;
  			width: 300px;
		}
	}

	&.frame-text--color {
		position: relative;
		cursor: pointer;
		padding: 10px;
		margin: 20px 0 0 0 ;
		border-bottom: 1px solid $color-1;

		text-transform: uppercase;
		@include font-size(13);

		transition: border 0.2s ease-in-out;

		&:hover {
			border-bottom: 1px solid white;
		}

		&:after {
			content: url('#{$image-path}triangle_small.png');
			position: absolute;
			text-align: center;
			right: 0;
			top: 6px;
			width: 42px;
			height: 42px;
		}
	}

	&.frame-text--down {
		padding: 20px 0 20px 10px;
		@include font-size(14);
		opacity: 1;

		&.frame-content {
			display: none;
		}
	}
}


.frame-text--loi {
    font-size: 18px;
    line-height: 31px;
    font-weight: 100;
}

.rotation {
	transform: rotate(-180deg);
}

.img-illu-middle{
	display: none;

    @include mq($from: desktop) {
		display: block;
	    position: absolute;
	    bottom: 150px;
	    left: 50%;
	    width: 82px;
	    height: 97px;
	   	background-image: url('#{$image-path}forme-orange.png');

	   	transform: translateX(-50%);
	}
}

/***** ACTUALITÉS *****/
.actu-category {
	width: 100%;
	background-color: $white;
	position: relative;

	&:after {
		display: block;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 10;
		background-color: transparent;
		border: 0px solid rgba($color-bg, 0.3);

		transition: border-width 0.2s ease-in-out;
	}

	p {
		text-align: left;
	}

	.no-touch & {
		&:focus, &:hover {
			cursor: pointer;

			&:after {
				border-width: 10px;
			}
		}
	}
}

.actu-category--header {
	width: 100%;
	position: relative;

	img {
		width: 100%;
		height: auto;
	}
}

.actu-category--title {
	display: inline-block;
	padding: 6px 10px 5px 9px;
	position: absolute;
	top: 55%;
	left: -5px;
	z-index: 20;
	
	background-color: $color-2;

	font-family: $font-title;
	@include font-size(20);
	line-height: 28px;
	text-transform: uppercase;
	color: $white;

    transition: left 0.2s ease-in-out;
}

.actu-category {
	
	.no-touch & {
		&:hover, &:focus {

			.actu-category--title {
				left: 20px;
			}
		}
	}

	.main-link {

    	@include mq($from: tablet) {
		position: absolute;	
		left: 25px;
    	bottom: 25px;
    	z-index: 30;
		}
	}
}

.actu-category--desc {
	width: 100%;
    background-color: #fff;
    padding: 20px 20px 40px;
    z-index: 15;

	@include mq($from: tablet) {
		padding: 20px 20px 100px;
	}

	@include mq($from: desktop) {
		@include font-size(13);
	}
}

/***** DOCUMENTATION *****/
.documentation-list {

	@include mq($from: tablet) {
		column-count: 2;
		column-gap: 40px;
	}

	@include mq($from: desktop) {
		column-count: 3;
	}

	@include mq($from: wide) {
		column-count: 4;
		column-gap: 60px;
	}

	li {
		@include clearfix;
		display: block;
		margin: 0 0 1.5em 0;

		a {
			display: block;
		}
	}
}

/***** CONTACT *****/
.contact-form {

	.champ-text {

		label, textarea {
			float: left;
		}

		textarea {
			color: grey;
			width: 100%;
			height: 150px;
			padding: 10px;
			margin: 15px 0 25px 0;

			border: 1px solid #d4d4d4;
			border-radius: 5px;
		}

		input {
			color: grey;
			width: 100%;
			padding: 10px;
			margin: 15px 0 25px 0;

			border: 1px solid #d4d4d4;
			border-radius: 5px;
		}

		button {
			height: 55px;
			width: 100%;
			float: right;
			margin: 15px 0 0 0;

			border: none;
			background-color: $color-1;

			color: $white;
			text-transform: uppercase;
			letter-spacing: 2px;

			transition: background-color 0.2s ease-in-out;

			&:hover {
				background-color: darken($color-2,10%);
			}

			@include mq($from: tablet) {
				width: 250px;
			}
		}
	}

	.champ-select {
		select {
			width: 100%;
			height: 38px;
			border: 1px solid #d4d4d4;
			border-radius: 5px;
			margin: 15px 0 25px;
			padding: 10px;

			color: grey;
			@include font-size(13);
		}
	}

	.champ-checkbox {
		input {
			position: relative;
			float: left;
			top:25px;
			left: 10px;
		}

		label {
			display: block;
			cursor: pointer;

			margin: 20px 0 0 35px;
		}

		p {
			@include mq($until: tablet) {
				padding: 15px 0 0 0;
			}
		}
	}

	.btn-radio {

		p { 
			@include mq($from: tablet) {
				display: inline-block;
			}
		}

		span {
			display: block;
			padding: 25px 0 0 0;

			@include mq($from: tablet) {
					display: inline-block;
			}

		}
		
		input {
   			position: relative;
   				
   			@include mq($until: tablet) {
				top: 3px;
			}

			@include mq($from: tablet) {
				margin: 0 0 0 25px;
			}
		}

		label {
			padding: 0 0 0 5px;
		}
	}
}

.formErrorContent {
    color: red;
    font-style: italic;
}

.star {
	position: relative;
	top: -4px;

	@include font-size(12);
	color: $color-1;
}

.background-white {
	    background: rgba(255, 255, 255, 0.19);
} 


/* 
   ###      ######    ########   ##     ##  
  ## ##    ##    ##      ##      ##     ##  
 ##   ##   ##            ##      ##     ##  
##     ##  ##            ##      ##     ##  
#########  ##            ##      ##     ##  
##     ##  ##    ##      ##      ##     ##  
##     ##   ######       ##       #######   
*/
/***** ACTUALITÉ LISTE *****/
.actu-item {
	background-color: $color-bg;
	position: relative;

	@include mq($from: tablet) {
		margin: 0 0 10px 0;
	}

	@include mq($from: desktop) {
		margin: 0 0 20px 0;
	}

	&:after {
		display: block;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 10;
		background-color: transparent;
		border: 0px solid rgba($color-bg, 0.3);

		transition: border-width 0.2s ease-in-out;
	}

	.no-touch & {
		&:hover, &:focus {
			cursor: pointer;

			&:after {
				border-width: 10px;
			}
		}
	}

	.main-link {
		position: absolute;
		left: 25px;
		bottom: 25px;
		z-index: 30;
	}
}

.actu-item--header {
	width: 100%;

	img {
		width: 100%;
		height: auto;
	}
}

.actu-item--content {
	background-color: $color-bg;
	padding: 25px 25px 110px 25px;

	p {
		line-height: 1.6;

		@include mq($from: desktop) {
			@include font-size(12);
			text-align: left;
		}
	}
}

.actu-item--date {
	display: block;
	width: 100%;
	margin: 0 0 10px 0;

	span {
		background-color: $color-1;
		padding: 2px 5px;

		font-family: $font-title;
		text-transform: uppercase;
		color: $white;

		@include mq($from: desktop) {
			@include font-size(13);
		}
	}
}

.pagination {
	width: 100%;
	margin: 20px 0 0 0;

	.multipage {
		text-align: right;
		
		li {
			display: inline-block;
			margin: 0 2px 4px 0;

			a {
				display: block;
				width: 30px;
				height: 30px;
				line-height: 28px;
				border: 1px solid $color-1;

				@include font-size(14);
				text-align: center;
				color: $color-1;

				transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

				.no-touch & {
					&:hover {
						color: $white;
						background-color: rgba($color-1, 0.8);
					}
				}
			}

			&.current a {
				color: $white;
				background-color: $color-1;
			}
		}
	}
}

/***** ACTUALITÉ DÉTAIL *****/
@include mq($from: tablet) {

	.col-actu--left {
		float: left;
	}

	.col-actu--right {
		float: right;
	}
}

.actu-detail--illu {
	width: 100%;
	position: relative;
	@include clearfix;
	display: block;

	.title-lev1--echo {
		position: relative;
		max-width: 100%;
		top: 0;
		left: 0;
		margin-bottom: 2px;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.col-actu--left {

	.title-lev1 {
		position: relative;
		max-width: 100%;
		top: 0;
		left: 0;
		margin-bottom: 30px;

		@include mq($from: desktop) {
			margin-bottom: 40px;
		}
	}
}

.alt-link--container {
	margin: 20px 0 0 0;

	@include mq($from: tablet) {
		margin: 40px 0 0 0;
	}

	@include mq($from: wide) {
		margin: 50px 0 0 0;
	}
}

.btn-paie,
.btn-rh {
	width: 100%;
	padding: 15px 0;
	border: 2px solid white;
	cursor: pointer;

	text-align: center;

	transition: background .2s ease-in-out, border .2s ease-in-out;

	img {
		display: block;
		padding: 10px;
		margin: auto;
	}

	&:hover {
		background: $color-1;
		border: 2px solid $color-1;
	}
}

.btn-active {
	position: relative;
	background: $color-1;
	border: 2px solid $color-1;
}

.content-rh,
.content-paie {
	display: none;
}

.visible {
	display: block;
}

.hidden {
	display: none;
}

/* 
##     ##  ########   ##    ##   ########   ####   #######   ##    ##    ######    
###   ###  ##         ###   ##      ##       ##   ##     ##  ###   ##   ##    ##   
#### ####  ##         ####  ##      ##       ##   ##     ##  ####  ##   ##         
## ### ##  ######     ## ## ##      ##       ##   ##     ##  ## ## ##    ######    
##     ##  ##         ##  ####      ##       ##   ##     ##  ##  ####         ##   
##     ##  ##         ##   ###      ##       ##   ##     ##  ##   ###   ##    ##   
##     ##  ########   ##    ##      ##      ####   #######   ##    ##    ######    
 */

 .mentions {
 	position: fixed;
    z-index: 9999;
	height: 100%;
	width: 100%;
	background: rgba(0,0,0, 0.5);
 }

 .mentions-container {
 	width: 75%;
 	height: 75%;
 	background: $white;
 	padding: 50px;

 	@include center();
 	overflow: scroll;
 }

 .mentions-title {
 	@include font-size(18);
 	font-weight: 600;
 }

 .hidden {
 	opacity: 0;
 	visibility: hidden;
 }

 .visible {
 	opacity: 1;
 	visibility: visible;
 }

 .close {
	position: absolute;
	z-index: 999;
	top: 10%;
	right: 15%;
	background: $color-1;
	padding: 15px 35px;
	cursor: pointer;

	text-align: center;
	color: white;
	text-transform: uppercase;
 }

/* 
########    #######    #######   ########   ########   ########   
##         ##     ##  ##     ##     ##      ##         ##     ##  
##         ##     ##  ##     ##     ##      ##         ##     ##  
######     ##     ##  ##     ##     ##      ######     ########   
##         ##     ##  ##     ##     ##      ##         ##   ##    
##         ##     ##  ##     ##     ##      ##         ##    ##   
##          #######    #######      ##      ########   ##     ##  
*/
.footer {
	background: url('#{$image-path}image-back-top.jpg');
	color: $white;

	.wrapper {
		@include clearfix;
	}

	.credit {
		float: left;
		padding: 13px 0;
		line-height: 28px;

		@include font-size(12);
		color: $white ;
	}

	.footer__links {
		
		padding-bottom: 20px;

		a {
			@include font-size(14);
			color: $white;
			padding-left: 35px;

			&:first-of-type {
				padding-left: 0;
			}

			&:hover {
				color: darken($color-1,20%);
			}
		}

		@include mq($from: tablet) {
			padding: 14px;
			display: inline-block;
			a {
				&:first-of-type {
				padding-left: 30px;
			}
			}
		}
	}

	.footer__logo {
		height: 51px;
		width: 98px;
		background: url('#{$image-path}logo-footer.png') no-repeat;
	}
}